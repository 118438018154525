import React, { useState, useEffect, useContext, useCallback } from "react"
import { Link, navigate } from 'gatsby'
import UserContext from './context/UserContext'
import {
  useAuth,
  SignIn,
  SignedIn,
  RedirectToSignIn,
  SignedOut,
  UserButton
  } from 'gatsby-plugin-clerk'
import { Modal, Loading, Input, Button, Spacer, Note } from '@geist-ui/react'
import { Sun, Moon, User, Award, Users } from '@geist-ui/react-icons'

import FP from '../../static/assets/Fanpowered-Logo-Black.png'


export default function HeadingBar (props) {
 
 const [loading, setLoading] = useState()
 const [signingin, setSigningIn] = useState()

 const { isLoaded, userId, sessionId, getToken } = useAuth();

 const { setMode, setAllSports } = useContext(UserContext)

 useEffect(() => {
        fetch(`/.netlify/functions/fauna-all/`, {
            body: JSON.stringify({
              index: 'sports_all'
            }),
          method: 'POST'}).then((response) => {
            return response.json()}).then((response) => setAllSports(response.data || []))
         .catch(error => console.log(error));
  }, [])


 function getAccount() {
   if (!isLoaded || !userId) {
     setSigningIn(true);
   }
 }

  return (<>
    <div className="heading-bar">
    	<div className="left">
         <SignedIn>
            <UserButton afterSignOutUrl={props.path} />
          </SignedIn>
          <SignedOut>
            <User color={"#fff"} size={16} onClick={() => getAccount()} />
            <Modal visible={signingin} className="sign-in" onClose={() => setSigningIn(false)}>
              <SignIn redirectUrl={props.path} />
             </Modal>
          </SignedOut>
    		<span className="link" onClick={() => navigate('/fights/')}>Fights</span>
    	</div>
    	<img
          src={FP}
          className="logo"
          alt="Fanpowered"
          width="400px"
          onClick={() => navigate('/')}
        />
        <div className="right">
    		<span className="link" onClick={() => navigate('/fighters/')}>Athletes</span>
    		{props.mode === 'light' ? <Moon color={"#fff"} size={16} onClick={() => (setMode('dark'), props.onChange('dark'))} /> : <Sun size={16} onClick={() => (setMode('light'), props.onChange('light'))} />}
    	</div>
    </div>
    <div className="bottom-bar">
    	<div onClick={() => navigate('/fights/')}>
    		<Award color={"#275aca"} size={20} />
    		<br />
    		<span>Fights</span>
    	</div>
    	<div onClick={() => navigate('/fighters/')}>
    		<Users color={"#275aca"} size={20} />
    		<br />
    		<span>Athletes</span>
    	</div>
    </div>
  </>)
}