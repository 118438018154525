// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-fight-js": () => import("./../../../src/pages/add-fight.js" /* webpackChunkName: "component---src-pages-add-fight-js" */),
  "component---src-pages-add-fighter-js": () => import("./../../../src/pages/add-fighter.js" /* webpackChunkName: "component---src-pages-add-fighter-js" */),
  "component---src-pages-fight-js": () => import("./../../../src/pages/fight.js" /* webpackChunkName: "component---src-pages-fight-js" */),
  "component---src-pages-fighters-js": () => import("./../../../src/pages/fighters.js" /* webpackChunkName: "component---src-pages-fighters-js" */),
  "component---src-pages-fights-js": () => import("./../../../src/pages/fights.js" /* webpackChunkName: "component---src-pages-fights-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-fight-template-js": () => import("./../../../src/templates/fightTemplate.js" /* webpackChunkName: "component---src-templates-fight-template-js" */),
  "component---src-templates-fighter-template-js": () => import("./../../../src/templates/fighterTemplate.js" /* webpackChunkName: "component---src-templates-fighter-template-js" */),
  "component---src-templates-sport-template-js": () => import("./../../../src/templates/sportTemplate.js" /* webpackChunkName: "component---src-templates-sport-template-js" */)
}

