import React from 'react'

export const defaultUserContext = {
  loggedin: true,
  currentuser: false,
  mode: 'light',
  subscriptions: [],
  allsports: [],
  upcomingorders: [],
  setLoggedIn: () => {},
  setCurrentUser: () => {},
  setMode: () => {},
  setSubscriptions: () => {},
  setAllSports: () => {},
  setUpcomingOrders: () => {}
}

const UserContext = React.createContext(defaultUserContext)

export default UserContext
