import React, { useContext } from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Header from "../components/Header"
import Footer from "../components/Footer"
import PropTypes from 'prop-types'
import { GeistProvider, CssBaseline } from '@geist-ui/react'

import StoreContext, { defaultStoreContext } from '../components/context/StoreContext'
import UserContext, { defaultUserContext } from '../components/context/UserContext'


class Layout extends React.Component {

 constructor(props){
  super(props);

  this.state = {
    mode: 'light',
    user: {
      ...defaultUserContext,
      setLoggedIn: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            loggedin: value
          }
        }))
      },
      setCurrentUser: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            currentuser: value
          }
        }))
      },
      setSubscriptions: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            subscriptions: value
          }
        }))
      },
      setAllSports: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            allsports: value
          }
        }))
      },
      setUpcomingOrders: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            upcomingorders: value
          }
        }))
      },
      setMode: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            mode: value
          }
        }))
      },
      setMode: (value) => {
        this.setState(state => ({
          user: {
            ...state.user,
            mode: value
          }
        }))
      },
    },
  }
}


  render() {

    const { path, children } = this.props

    return  <>
    <Helmet>
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
    </Helmet>
    <GeistProvider themeType={this.state.mode}>
    <CssBaseline />
    <UserContext.Provider value={this.state.user}>
    <header className={`site-header ${this.state.mode}`} id="header">
        <Header mode={this.state.mode} path={path} onChange={(resp) => this.setState({mode: resp})} />
     </header>
    <div className={`site-wrapper ${this.state.mode} ${this.state.user.upcomingorders.length > 0  && `draft`}`} id="site-wrapper">
      {children}
    </div>
     <Footer />
    </UserContext.Provider>
    </GeistProvider>
    </>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

