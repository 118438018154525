import React from "react"
import { Link } from 'gatsby'

import Instagram from '../../static/assets/Instagram.png'
import TikTok from '../../static/assets/TikTok.png'

export default () => (
     <footer className="footer">
     	<div className="social-icons">
     		<a href="https://www.instagram.com/fanpoweredfights" target="_blank"><img src={Instagram} alt="Instagram" /></a>
     		<a href="https://www.tiktok.com/@fanpowered" target="_blank"><img src={TikTok} alt="TikTok" /></a>
     	</div>
     	<p>©Fanpowered. All Rights Reserved.</p>
     	<div className="flex">
     		<Link to="/terms">Terms</Link>
     		<Link to="/privacy">Privacy</Link>
     	</div>
     </footer>
  
)